import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Title } from './Title';

export default function FullScreenError() {
  return (
    <>
      <div className="px-4 absolute">
        <Title />
      </div>
      <div className="min-h-screen bg-background flex items-center justify-center p-4">
        <Card className="w-full max-w-md">
          <CardHeader>
            <CardTitle className="text-3xl font-extrabold text-center">
              Junior developer homepage
            </CardTitle>
            <CardDescription className="text-center">Ooooppsss....</CardDescription>
          </CardHeader>
          <CardContent className="space-y-4">
            <p className="text-center text-muted-foreground">Looks like something went wrong.</p>
          </CardContent>
          <CardFooter className="flex justify-center">
            <Link to="/">
              <Button>
                <ArrowLeft className="mr-2 h-4 w-4" />
                Back to Home
              </Button>
            </Link>
          </CardFooter>
        </Card>
      </div>
    </>
  );
}
