import { Auth0ProviderOptions } from '@auth0/auth0-react';

export default {
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  cacheLocation: 'localstorage',
  useCookiesForTransactions: true,
  cookieDomain: window.location.hostname.includes('localhost') ? 'localhost' : 'codiqle.com',
  authorizationParams: {
    audience: import.meta.env.VITE_AUTH0_AUDIENCE,
    redirect_uri: window.location.origin,
  },
} as Auth0ProviderOptions;
