import { createBrowserRouter, Navigate, redirect } from 'react-router-dom';
import { getSessionById, joinSession } from './api/sessions';
import { App } from './App';
import { SignIn } from './components/auth/SignIn';
import { SignOut } from './components/auth/SignOut';
import FullScreenError from './components/FullScreenError';
import { InvalidSession } from './pages/InvalidSession';
import { JoinSession } from './pages/JoinSession';
import { SessionPage } from './pages/session';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <FullScreenError />,
    children: [
      {
        index: true,
        element: <Navigate replace to="join" />,
      },
      {
        path: 'join',
        element: <JoinSession />,
        action: async ({ request }) => {
          const formData = await request.formData();
          try {
            const sessionId = formData.get('sessionId');
            if (!sessionId) {
              return redirect('/invalid');
            }
            return redirect(`/live/${sessionId}`);
          } catch (error) {
            console.error(error);
            return redirect('/invalid');
          }
        },
      },
      {
        path: 'invalid',
        element: <InvalidSession />,
      },
      {
        path: 'live/:sessionId',
        element: <SessionPage />,
        loader: async ({ params }) => {
          const sessionId = params.sessionId as string;
          try {
            const session = await getSessionById(sessionId);
            if (!session) {
              return redirect('/invalid');
            }
            joinSession(sessionId);
            return { session };
          } catch (error) {
            console.error(error);
            return redirect('/invalid');
          }
        },
      },
    ],
  },
  {
    path: '/auth',
    children: [
      {
        index: true,
        element: <Navigate replace to="sign-in" />,
      },
      {
        path: 'sign-in',
        element: <SignIn />,
      },
      {
        path: 'sign-out',
        element: <SignOut />,
      },
    ],
  },
]);

export default router;
