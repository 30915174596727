import { getSessionById } from '@/api/sessions';
import { Title } from '@/components/Title';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Editor } from '@monaco-editor/react';
import { useLoaderData } from 'react-router-dom';

export const SessionPage = () => {
  const { session } = useLoaderData() as { session: Awaited<ReturnType<typeof getSessionById>> };
  console.log(session);
  const q1 = session.questions[0];
  return (
    <>
      <div className="flex h-full max-h-screen flex-col gap-2">
        <div className="flex h-14 justify-between items-center border-b px-4 lg:h-[60px] lg:px-6">
          <div>
            <Title />
          </div>
          <div className="flex gap-2">
            <Input placeholder="Choose the language" />
            <Button variant="secondary">Next</Button>
            <Button>Complete Session</Button>
          </div>
        </div>
        <div className="flex-1">
          <div className="p-4 h-full">
            <div className="flex flex-col gap-4 h-full">
              <div className="grid h-full gap-4 lg:grid-cols-[auto_350px] md:grid-cols-[auto_250px]">
                <Editor
                  className="rounded-md border"
                  defaultLanguage="javascript"
                  value={q1.code}
                  // onChange={(value) => setCode(value ?? '')}
                  options={{ minimap: { enabled: false } }}
                />
                <div className="rounded-md border bg-muted p-4">
                  <div className="text-lg font-semibold">{q1.title}</div>
                  <div className="text-sm">{q1.description}</div>
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <Button>Submit</Button>
                <Button variant="secondary">Reset</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
