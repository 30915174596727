import { Title } from '@/components/Title';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { TriangleAlert } from 'lucide-react';
import { useSearchParams, useSubmit } from 'react-router-dom';

export const JoinSession = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const submit = useSubmit();
  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submit(
      {
        sessionId: searchParams.get('id'),
      },
      { method: 'post' },
    );
  };
  return (
    <>
      <div className="px-4 absolute">
        <Title />
      </div>
      <form onSubmit={submitHandler} className="w-full h-full flex justify-center items-center">
        <Card className="w-[380px] h-fit">
          <CardHeader>
            <CardTitle>Join Session</CardTitle>
            <CardDescription>
              You are about to join the coding session. <br />
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-4">
            <div className="flex w-full max-w-sm items-center space-x-2">
              <Input
                required
                id="name"
                placeholder="Enter session ID"
                value={searchParams.get('id') ?? ''}
                onChange={(ev) => setSearchParams({ id: ev.target.value })}
              />
              <Button type="submit">Join</Button>
            </div>
            <Alert className="space-x-2">
              <TriangleAlert />
              <AlertDescription className="text-muted-foreground">
                Take into account that some sessions have time limit, so join when you are ready.
              </AlertDescription>
            </Alert>
          </CardContent>
        </Card>
      </form>
    </>
  );
};
