import fetcher from './fetcher';
import { SessionId } from '@server/session';

export const getSessionById = async (sessionId: SessionId) => {
  return fetcher.get(`/sessions/${sessionId}`).then((res) => res.data);
};

export const joinSession = async (sessionId: SessionId) => {
  return fetcher.patch(`/sessions/${sessionId}`).then((res) => res.data);
};

export const endSession = async (sessionId: SessionId) => {
  return fetcher.patch(`/sessions/${sessionId}`, { completed: true }).then((res) => res.data);
};
