import axios from 'axios';
import { Auth0Client } from '@auth0/auth0-spa-js';
import authConfig from '@/consts/auth.const';

const auth0 = new Auth0Client(authConfig);

const fetcher = axios.create({
  baseURL: `${import.meta.env.VITE_SERVER_URL}/api`,
  withCredentials: true,
});

fetcher.interceptors.request.use(
  async (config) => {
    try {
      const token = await auth0.getTokenSilently();
      config.headers['Authorization'] = `Bearer ${token}`;
    } catch (error) {
      console.error('Failed to get token', error);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default fetcher;
